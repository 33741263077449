import React from "react";
import Text from "@components/common/Text";

const TextApp = () => {
    return (
        <>
            <Text>
                Descarga iSanitarios y prepárate para destacar en tus oposiciones a Sanidad.
                <br />
                <br />
                La aplicación más completa para acceder a cualquier puesto en el campo de la
                Sanidad.
                <br />
                <br />
                {/*<b>Especializada para:</b>
                <ul className="ml-0 mb-3 mt-5">
                    <li>
                        <span role="img" aria-labelledby="">
                            ⭐
                        </span>{" "}
                        Personal ICS y Cataluña
                    </li>
                    <li>
                        <span role="img" aria-labelledby="">
                            ⭐
                        </span>{" "}
                        Proceso de estabilización 2023 ICS
                    </li>
                    <li>
                        <span role="img" aria-labelledby="">
                            ⭐
                        </span>{" "}
                        Transversal para todas las categorías ICS Y Cataluña
                    </li>
                </ul>
                <br />*/}
                <strong>
                    Personaliza tu preparación con módulos descargables para adquirir solo lo que
                    necesitas:
                </strong>
                <ul className="ml-0 mb-3 mt-5">
                    <li>
                        <span role="img" aria-labelledby="">
                            ✅
                        </span>{" "}
                        Transversal
                    </li>
                    <li>
                        <span role="img" aria-labelledby="">
                            ✅
                        </span>{" "}
                        Técnico en Cuidados Auxiliares de Enfermería:
                        <br />
                        <b>Número de plazas convocadas por proceso de selección:</b>
                        <br />
                        a. Concurso de méritos del proceso de estabilización: 801 plazas. <br />
                        b. Concurso oposición del proceso de estabilización: 278 plazas.
                        <br />
                        c. Concurso oposición del proceso ordinario: 555 plazas totales, 111 plazas
                        por el turno de promoción interna y 444 plazas por el turno libre.
                        <br />
                    </li>
                    <li>
                        <span role="img" aria-labelledby="">
                            ✅
                        </span>{" "}
                        Celador:
                        <br />
                        <b>Número de plazas convocadas por proceso de selección:</b>
                        <br />
                        a. Concurso de méritos del proceso de estabilización: 118 plazas.
                        <br />
                        b. Concurso oposición del proceso de estabilización: 51 plazas.
                        <br />
                        c. Concurso oposición del proceso ordinario: 137 plazas totales, 27 por el
                        turno de promoción interna y 110 por el turno libre.
                        <br />
                    </li>
                </ul>
                <br />
                <br />
                <b>¿Qué obtendrás?</b>
                <br />
                <br />
                ▶︎ Practica lo que estás estudiando.
                <br />
                ▶︎ Refuerza tus puntos débiles.
                <br />
                ▶︎ Averigua a qué te enfrentas con exámenes reales de años anteriores.
                <br />
                ▶︎ Estudia donde y cuando quieras con tu móvil y/o tableta.
                <br />
                ▶︎ Amplía tus conocimientos de manera interactiva y dinámica.
                <br />
                <br />
                <b>Las mejores características:</b>
                <br />
                <br />
                <span role="img" aria-labelledby="">
                    😷
                </span>{" "}
                RÁNKINGS
                <br />
                <br />
                Compárate con el resto de usuarios y comprueba tu progreso. Tendrás una imagen
                cercana de tu estado en la preparación en esta oposición.
                <br />
                <br />
                <span role="img" aria-labelledby="">
                    ⚕️
                </span>{" "}
                ORDENADO POR TEMAS
                <br />
                <br />
                Podrás prepararte temas concretos para reforzar las áreas que necesites. La mejor
                manera de mejorar tu preparación en Sanidad.
                <br />
                <br />
                <span role="img" aria-labelledby="">
                    🏥
                </span>{" "}
                SIMULACIONES DE EXAMEN
                <br />
                <br />
                Tienes a tu disposición un simulador de examen para que puedas comprobar, antes de
                presentarte al examen, cómo sería un posible examen.
                <br />
                <br />
                <span role="img" aria-labelledby="">
                    💉
                </span>{" "}
                EXÁMENES OFICIALES
                <br />
                <br />
                Además, podrás practicar con exámenes de anteriores convocatorias.
                <br />
                <br />
                <span role="img" aria-labelledby="">
                    💊
                </span>{" "}
                ACTUALIZADO AL MÁXIMO
                <br />
                <br />
                No hay ninguna aplicación más actualizada que esta. Somos el mejor aliado para que
                puedas aprobar esta oposición.
                <br />
                <br />
                <span role="img" aria-labelledby="contacto">
                    🩺
                </span>{" "}
                CONTACTO PERMANENTE
                <br />
                <br />
                No te dejamos solo. Te acompañamos durante todo el proceso de preparación para
                conseguir tu objetivo.
                <br />
                <br />
                Haz realidad tu sueño y asegura tu futuro en el campo de la Sanidad.
            </Text>
        </>
    );
};

export default TextApp;
