import React from "react";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import SmallBanner from "@components/common/SmallBanner";
import Title from "@components/common/Title";
import Testimonials from "@components/sections/LazyTestimonials";
import Image from "@components/common/CloudinaryImage";
import Screenshots from "@components/sections/AppScreenshots";
import DownloadApp from "@components/sections/DownloadApp";
// import AppContent from "@components/sections/LazyAppContent";
import TextApp_iGuB from "./igub";
import TextApp_iCorreos from "./icorreos";
import TextApp_iMossos from "./imossos";
import TextApp_iGC from "./igc";
import TextApp_iSanitarios from "./isanitarios";
import TextApp_iPoliciaMadrid from "./ipoliciamadrid";

const App = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const GetTextApp = () => {
        if (item.name === "icorreos") {
            return TextApp_iCorreos();
        } else if (item.name === "igub") {
            return TextApp_iGuB();
        } else if (item.name === "imossos") {
            return TextApp_iMossos();
        } else if (item.name === "igc") {
            return TextApp_iGC();
        } else if (item.name === "isanitarios") {
            return TextApp_iSanitarios();
        } else if (item.name === "ipoliciamadrid") {
            return TextApp_iPoliciaMadrid();
        } else return null;
    };

    return (
        <>
            <Seo
                title={`Aplicación ${item.title}`}
                description={`${item.large_description || item.description}`}
            />
            <Layout>
                <SmallBanner
                    title={item.title}
                    subtitle={item.description}
                    filename={`apps/${item.name}/banner`}
                />

                <div className="container lg:px-0 py-20">
                    <Title className="text-left">
                        ¿Qué es <span className="">{item.title}</span>?
                    </Title>

                    <GetTextApp />

                    <div className="mt-10">
                        <Image
                            tag={`mockup ${item.name}`}
                            className=""
                            filename={`apps/${item.name}/mockup2`}
                        />
                    </div>
                </div>

                <DownloadApp
                    className="bg-gray-100 bg-opacity-50"
                    ios={item.ios_url}
                    android={item.android_url}
                />

                {/*<AppContent item={item} className="" />*/}

                <Screenshots className="bg-gray-100 bg-opacity-50" item={item} />

                <Testimonials className="" type={"app"} uid={item.name} />
            </Layout>
        </>
    );
};

export default App;
